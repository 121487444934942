import { onLCP, onINP, onCLS } from "web-vitals";

type Metric = {
  id: string;
  name: string;
  value: number;
  delta: number;
};

type TransformedMetric = {
  valueRounded: number;
  deltaRounded: number;
} & Metric;

type Callback = (metric: TransformedMetric) => void;

export function onCoreWebVitals(cb: Callback) {
  onCLS(transformMetric);
  onINP(transformMetric);
  onLCP(transformMetric);

  function transformMetric({ id, name, value, delta }: Metric) {
    const valueRounded = Math.round(name === "CLS" ? value * 1000 : value);
    const deltaRounded = Math.round(name === "CLS" ? delta * 1000 : delta);

    cb({
      id,
      name,
      value,
      delta,
      valueRounded,
      deltaRounded,
    });
  }
}
